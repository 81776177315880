<template>
	<div>
		<v-alert
			outlined
			type="warning"
			prominent
		>
			このページは図書館情報学会第72回研究大会用のデモです。<br/>
			口頭発表「図書館及び社会における司書課程カリキュラムの有効性」のオンライン質問紙調査を体験していただけます。<br/>
			回答結果がサーバに送信されることはありません。
		</v-alert>
		<v-container>
			<v-row>
				<v-col>
					<div class="mt-5">
						<h1>
							司書課程を修了した卒業生を対象としたアンケート調査
						</h1>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<UserSurveyDemo/>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserSurveyDemo from '@/components/UserSurveyDemo'

export default {
	name: 'UserSurveyHome',
	components: {
		UserSurveyDemo,
	},
	created () {
		this.setPublicSurveyAccessed(true)
		this.setPublicSurveyStartAt(new Date().toISOString())
		if (this.publicSurveyAnswered) {
			this.clearPublicSurveyAnswers()
			this.clearScaleItemAnswers()
			this.clearScaleItemNotAnswered()
			this.clearMatrixItemAnswers()
			this.clearMatrixItemNotAnswered()
			this.clearMatrixEmptyItemAnswers()
		}
	},
	computed: {
		...mapGetters({
			publicSurveyAnswered: 'statePublicSurveyAnswered',
		}),
	},
	methods: {
		...mapActions([
			'setPublicSurveyAccessed',
			'clearPublicSurveyAnswers',
			'setLoading',
			'setPublicSurveyStartAt',
			'clearScaleItemAnswers',
			'clearScaleItemNotAnswered',
			'clearMatrixItemAnswers',
			'clearMatrixItemNotAnswered',
			'clearMatrixEmptyItemAnswers'
		]),
	}
}
</script>